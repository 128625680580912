


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.label_title {
  // display: flex;
  border-left: 7px solid rgb(92, 107, 232);
  padding: 0;
  line-height: 20px;
  font-size: 20px;
}
